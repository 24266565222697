<!--
    AUTHOR: Ryan Lang
    LATEST UPDATED DATE: 12/22/2022
    DESCRIPTION: Dashboard widget/card that represents an graphed value according to defined parameter and date/time range values
    ADDITIONAL INFORMATION: //
-->
<template>

 <div ref="widgetContent" class="h-full p-4" >
  
   <div v-if="noData" class="flex items-center justify-center text-center text-xl  text-slate-500 h-full">No Data</div>
   <v-chart  v-else class="chart" ref="chart" :option="chartOptions" autoresize />

</div>

 </template>
 
 <script setup>

 /****external imports ****/
 import { ref, computed,watch,watchEffect,onMounted,provide} from "vue";
 import { useStore } from 'vuex' 
 import { useRouter } from "vue-router";
 import { format,parseISO,addHours } from 'date-fns';
 import {convertZonedTimetoUTC, convertUTCDateToLocalDate} from '@/scripts/dateHelpers'
 
 /****external imports ****/

import { use } from 'echarts/core';
  import { CanvasRenderer } from 'echarts/renderers';
  import { LineChart } from 'echarts/charts';
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
GridComponent,  ToolboxComponent 
  } from 'echarts/components';
  import VChart, { THEME_KEY } from 'vue-echarts';

  use([
  CanvasRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,ToolboxComponent 
  ]);
  
  provide(THEME_KEY, 'light');


 //component property definitions
 const props = defineProps({  
   Settings: Array,
   ReloadData: Boolean,
   ExportDataTrigger: Boolean
 });
 
 const triggerReload = computed(() => props.ReloadData);
 const triggerExportData = computed(() => props.ExportDataTrigger);

 //reactive variable/object definitions
 const store = useStore();
 const router = useRouter();
 const meter = computed(() => {return store.getters.meter});
 const userName = computed(() => {return store.state.loggedinEmail.toLowerCase()}); 
const chart = ref(null);
 const showIcons = ref(true);
 const noData = ref(false);

const chartColors = ref([]);
const chartSeries = ref([]);
const chartOptions = ref({});
const chartXaxis = ref([]);
const chartYaxis = ref([]);
const chartLegend = ref([]);
const widgetContent = ref(null); 
const isGraphOverlayModalOpen = ref(false);

 const invalidDashboard = ref(false); 

 const emit = defineEmits(['dataReloaded','dataExported']);



 //check if prop has been set to true, if so reload data.
 watchEffect(async () => {

    if(triggerReload.value)
    {
      
      await loadAndProcessDashboard();
    }
    
});

watchEffect(async () => {

  if(triggerExportData.value)
  {
          
      emit('dataExported', true);

  }

});


 //returns the start date/time value of the dashboard time interval

 function getTimeIntervalStart(){
 
     //type should be "graph"
 
     if(props.Settings.TimeInterval) //predefined time intervals shown to end user in user interface
     {
        return addHours(new Date(),parseInt(props.Settings.TimeInterval)).toISOString();
     }
     else  
         return props.Settings.CustomTimeIntervalStart;  //custom time intervals specified by end user
 
 }
 
 
 //returns the ending date/time value of the dashboard time interval
 function getTimeIntervalEnd(){
 
     if(props.Settings.TimeInterval)
     {
        return new Date().toISOString();
     }
     else 
         return props.Settings.CustomTimeIntervalEnd;        
     
 }

 //reutrns array of parameters to be used in api call
 function getParams(paramObj){
 
 let params = [];
 for (var p of paramObj) params.push(p.param);
 
 return params;  
 }
 
 //loads the data   
 async function loadDashboardData(){
     
    let returnVal = [];
    let thisParams = getParams(props.Settings.Parameters);
           
    let getTimeIntervalStartVal = getTimeIntervalStart();
    let getTimeIntervalEndVal = getTimeIntervalEnd();

    let thisStartDateTime = getTimeIntervalStartVal;
    let thisStartDateTimeUTC = convertZonedTimetoUTC(thisStartDateTime,meter.value.timeZone.offset);
    ///
    let thisEndDateTime = getTimeIntervalEndVal;
    let thisEndDateTimeUTC = convertZonedTimetoUTC(thisEndDateTime,meter.value.timeZone.offset);

    let thisStartDay = format(thisStartDateTimeUTC, "yyyy") + "-" + format(thisStartDateTimeUTC, "MM") + "-" + format(thisStartDateTimeUTC, "dd");
    let thisEndDay = format(thisEndDateTimeUTC, "yyyy") + "-" + format(thisEndDateTimeUTC, "MM") + "-" + format(thisEndDateTimeUTC, "dd");
    let thisStartTime = format(thisStartDateTimeUTC, "HH") + ":" + format(thisStartDateTimeUTC, "mm");
    let thisEndTime = format(thisEndDateTimeUTC, "HH") + ":" + format(thisEndDateTimeUTC, "mm");

     var params = {
        request: "s3_DATA",
        username: userName.value,
        metername: meter.value.name,
        startDay: thisStartDay, // does a range of data
        startTime: thisStartTime, // post-request parsing
        endDay: thisEndDay,
        endTime: thisEndTime,
        isParseFifteen: true,
        topics: thisParams, // projection expression
      };

     let thisr = await store.dispatch("s3_API", params);
      
     if (thisr.table.length > 0) {
      returnVal = thisr.table;    
    }

     return returnVal;
 
 }
 
 
 //process the returned data to be shown in the ui
 function procData(params, arr){  

  let seriesArray = [];
  let headerArray = [];
  let colorsArray = [];

  let yLabels = new Set();
  let xLabels = new Set();

  for(let p of params){
    
    chartLegend.value.push(p.displayname);
    //add to header array
    headerArray.push(p);
    colorsArray.push(p.color);
    yLabels.add(p.uom);

    let obj = {};

    obj["name"]= p.displayname;
    obj["type"] = 'line';
    obj["symbol"] = 'none';

    let dataArr = [];

    (arr).map((el) => {

      let thisDateStr = el.date + 'T' + el.time + ':00Z';
      let thisDtUTC = new Date(thisDateStr);
      let localDt = convertUTCDateToLocalDate(thisDtUTC);
      let paramVal = parseFloat(el[p.param]);

      if(!Number.isNaN(paramVal))
      {
  
        xLabels.add(format(localDt,"Pp"));        
        dataArr.push(paramVal);            
      }

    }); 

    if(dataArr.length > 0){

    obj['data'] = dataArr.sort((a, b) => {
      
      let thisDateA = new Date(a.x);
      let thisDateB = new Date(b.x);
      if (thisDateA < thisDateB) {
        return -1; // a comes before b
      } else if (thisDateA > thisDateB) {
        return 1; // a comes after b
      } else {
        return 0; // a and b are equal
      }

    });

    seriesArray.push(obj);

    }

  }

  if(seriesArray.length > 0)
  {
    chartSeries.value = seriesArray;  
    chartColors.value = colorsArray;  
    chartYaxis.value = [];
    //add y label values if only 1
    if(yLabels.size == 1)
    {
      for (const i of yLabels) {

        let yobj = {'title': {
              'text': i,
              style: {              
                fontSize: '.75rem',
                fontFamily: '"Noto Sans",Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-yaxis-title',
              },
            }};
        
        chartYaxis.value.push(yobj);
      }    
    }

    chartXaxis.value = Array.from(xLabels);

    chartOptions.value = 
      {
    title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
    }
  },
  legend: {
    type:'scroll',
    orient: 'horizontal',
    left: 10,
    top: 25,
  
    data: chartLegend.value,
  },
  grid: {

    left: '2%',
    right: '2%',
    bottom: '2%',
    containLabel: true
  },
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      restore: {},
      saveAsImage: {
        name: meter.value.name + '_' + props.Settings.FullName
      }
    }
  },
  xAxis: {
    boundaryGap: true,
    data: chartXaxis.value
  },
  yAxis: {
    type: 'value'
  },
  series: chartSeries.value
  };


 } else {
    //flag no data
    noData.value = true;

  }
}


function updateOptions(thisdata){
  if(thisdata.length >0)
    //process incoming data for chart elements
    procData(props.Settings.Parameters, thisdata); 
  else
    noData.value = true;
}


function closeGraphOverlayModal(){

  isGraphOverlayModalOpen.value = false;

}
function openGraphOverlayModal(){

  isGraphOverlayModalOpen.value = true;

}



async function loadAndProcessDashboard(){

  try
    {
      const loadDashRes = await loadDashboardData();

      emit('dataReloaded', true);
      updateOptions(loadDashRes);

    } 
    catch
    {
      invalidDashboard.value = true;
      emit('dataReloaded', true);
    } 
}

loadAndProcessDashboard();

defineExpose({
  openGraphOverlayModal,
  chartOptions
});


 </script>

 <style>
 
 </style>