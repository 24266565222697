<!--
    AUTHOR: Ryan Lang
    LATEST UPDATED DATE: 12/22/2022
    DESCRIPTION: Dashboard widget/card that represents an graphed value according to defined parameter and date/time range values
    ADDITIONAL INFORMATION: //
-->
<template>

  <div class="widget-header rounded-t-md bg-white px-2 shadow flex justify-between w-full min-h-[40px] z-1 items-center">
    <span class="widget-title text-xs xl:text-sm">
      {{ props.Settings.FullName }}  <InformationCircleIcon class="h-4 w-4 text-primary/40 inline hover:cursor-pointer" @click="() => { isInfoModalOpen = true}"  />
    </span>

    
    <span class="widget-actions">            
 
            <Popover class="relative">
              <PopoverButton><EllipsisVerticalIcon class="h-full w-5 text-black hover:cursor-pointer" /></PopoverButton>

              <transition
                enter-active-class="transition duration-50 ease-out"
                enter-from-class="translate-y-4 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-50 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-4 opacity-0"
              >
                <PopoverPanel class="widget-menu absolute z-20 top-[40px] right-0 backdrop-blur  bg-[#eceff8] opacity-80  text-primary text-left rounded-md shadow-xl p-2 min-w-[200px] text-xs">
                  <div class="">
                    <button class="block rounded-md w-full text-left py-1 px-2 hover:cursor-pointer hover:bg-white" @click="reloadWidgetData"> <ArrowPathIcon class="inline w-4 mr-2" /> Refresh </button>
                    <button class="block rounded-md w-full text-left py-1 px-2 hover:cursor-pointer hover:bg-white" @click="redirectToWidget" v-if="props.Settings.Type==='graph'"> <ArrowsPointingOutIcon class="inline w-4 mr-2" /> Fullscreen </button>
                    <button class="block rounded-md w-full text-left py-1 px-2 hover:cursor-pointer hover:bg-white" @click="editWidget"> 
                      <PencilIcon class="inline w-4 mr-2" /> Edit </button>
                      <button class="block rounded-md w-full text-left py-1 px-2 hover:cursor-pointer hover:bg-white" v-if="props.Settings.Type==='graph'" @click="exportData"> 
                      <ArrowUpTrayIcon class="inline w-4 mr-2" /> Export Data </button>
                    <button class="block rounded-md w-full text-left py-1 px-2 hover:cursor-pointer hover:bg-white" @click="duplicateWidget"> <DocumentDuplicateIcon class="inline w-4 mr-2" /> Duplicate </button>
                    <button class="block rounded-md w-full text-left py-1 px-2 hover:cursor-pointer hover:bg-white"  @click="openDeleteModal"> <TrashIcon class="inline w-4 mr-2" /> Delete </button>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

      </span>

  </div>
  <div ref="widgetContent" class="widget-content grow h-full w-full z-0" :class="widgetContentClass">
   
   <div v-if="dataLoading" class="flex justify-center items-center w-full h-full">
     <div class="btn-loader w-10 h-10"></div>
    
    </div> 

    <GraphedValueWidget v-if="props.Settings.Type==='graph'"
      :Settings="props.Settings" 
      :ReloadData="reloadDataTrigger"
      :RezizeTrigger="resizeTrigger"
      :ExportDataTrigger="exportWidgetDataTrigger"
      @isDataLoading="isDataLoading(val)"
      @dataReloaded="reloadWidgetDataComplete"
      @dataExported="exportDataComplete"
      ref="graphWidget"
      /> 

    <AccumulatedValueWidget v-else-if="props.Settings.Type==='value'"
      :Settings="props.Settings"
      :ReloadData="reloadDataTrigger"
      :ExportDataTrigger="exportWidgetDataTrigger"
      @isDataLoading="isDataLoading(val)" 
      @dataReloaded="reloadWidgetDataComplete()"
      ref="valueWidget"
      />

  </div>


      <!-- Confirm delete dash -->
      <GDialog v-model="isDeleteModalOpen" transition="custom-from-bottom-transition">      
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">
            <div
              class="
                w-full
                max-w-2xl
                transform
                overflow-hidden
                rounded-lg
                bg-white
                p-6
                text-left
                align-middle
                shadow-xl
                transition-all
              "
            >
              <h3 class="text-lg font-bold leading-6 text-gray-900">CONFIRM DELETE</h3>

              <div class="mt-8">
                <h2 class="font-bold mb-2">Are you sure?</h2>
                <p class="text-sm mb-4">
                  This action will remove this data widget from your account.
                </p>

                <div class="text-alert text-left text-xs mb-2" v-if="showDeleteError"><ExclamationTriangleIcon class="inline h-6 w-6"/> 
                  Problem removing data widget.</div>

                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    bg-primary
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    mr-4
                    transition
                    hover:bg-primary/80
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                  "
                  @click="confirmDelete"
                >
                <span v-if="showDeleteLoading" class="loading">One moment <span class="btn-loader"></span></span>
                <span v-else>Confirm</span> 
                  
                </button>
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    bg-blue-100
                    px-4
                    py-2
                    text-sm
                    font-medium
                    transition
                    text-blue-900
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                  "
                  @click="closeDeleteModal"
                >
                  Cancel
                </button>
              </div>
            </div>
        </div>
      </div>
    </GDialog>        
    <!-- Confirm delete dash -->

     <!-- Info modal  -->

     <GDialog v-model="isInfoModalOpen" transition="custom-from-bottom-transition">      
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">
            <div
              class="
                w-full
                max-w-2xl
                transform
                overflow-hidden
                rounded-lg
                bg-white
                p-6
                text-left
                align-middle
                shadow-xl
                transition-all
              "
            >
              <h3 class="text-lg font-bold leading-6 text-gray-900"></h3>

              <div class="mt-8">
                <h2 class="font-bold mb-2">{{props.Settings.FullName}}</h2>
                <p class="text-sm mb-4">
                  {{props.Settings.Comment}}
                </p>

                
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    bg-blue-100
                    px-4
                    py-2
                    text-sm
                    font-medium
                    transition
                    text-blue-900
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                  "
                  @click="() => { isInfoModalOpen = false}"
                >
                  Close
                </button>
              </div>
            </div>
        </div>
      </div>
    </GDialog>

        
    <!-- Info Modal -->

 </template>
 
 <script setup>
 
 /****external imports ****/
 import { ref, watchEffect, computed,defineComponent, h, markRaw } from "vue";
 import { useStore } from 'vuex' 
 import { useRouter } from "vue-router";
 import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
 import {ArrowPathIcon, ArrowsPointingOutIcon, ExclamationTriangleIcon,InformationCircleIcon,EllipsisVerticalIcon} from '@heroicons/vue/20/solid'
 import { toast } from 'vue-sonner'
import { ArrowUpTrayIcon, DocumentDuplicateIcon, PencilIcon, TrashIcon} from "@heroicons/vue/24/outline";
import { format,parseISO,addHours } from 'date-fns';
 import {convertZonedTimetoUTC, convertUTCDateToLocalDate} from '@/scripts/dateHelpers'

 /****external imports ****/
 
 //component property definitions
 const props = defineProps({
   WidgetId: String,
   Settings: Array,
   ResizeTrigger: Boolean,
   WidgetDeletedTrigger: Boolean
 });

 //reactive variable/object definitions

 const store = useStore();
 const router = useRouter();
 const meter = computed(() => {return store.getters.meter});
 const userName = computed(() => {return store.state.loggedinEmail.toLowerCase()}); 
 const isDeleteModalOpen = ref(false);
 const dataLoading = ref(true);
 const showDeleteLoading = ref(false);
 const widgetPath = ref("/mymeters/managemeter/viewdatawidget");
const reloadDataTrigger = ref(false);
const editWidgetTrigger = ref(false);
const duplicateWidgetTrigger = ref(false);
const exportWidgetDataTrigger = ref(false);
const resizeTrigger = ref(false);
const graphWidget = ref(null);
const isInfoModalOpen = ref(false);
const graphOptions = computed(() => graphWidget.value?.chartOptions);
const widgetContentClass = ref('overflow-hidden');

const thisWidget = computed(() => { return {
    id: props.WidgetId,
    settings: props.Settings,
    chartData: graphOptions.value
  }});



//emit definitions
const emit = defineEmits(['deleteWidget', 'editWidget','duplicateWidget', 'widgetResizedComplete']);

const widgetResized = computed(()=>{return props.ResizeTrigger});
const wigdetDeletedTrigger = computed(()=>{return props.WidgetDeletedTrigger});

const confirmDelete = (event) => {

  showDeleteLoading.value = true; 
  emit('deleteWidget');
  
}

//fix for sonner
const tClose = defineComponent({
  setup(_props, { emit }) {
    return () =>
      h('button', {
        onClick: () => emit('closeToast')
      }, 'Click me to close')
  }
})

watchEffect(async () => {

  
  if(widgetResized.value)
  { 
    emit('widgetResizedComplete');
  }

  if(wigdetDeletedTrigger.value)
  {

    isDeleteModalOpen.value = false;
    showDeleteLoading.value = false;
  }

})


//toggles the delete confirmation dialog box
function closeDeleteModal(){
    isDeleteModalOpen.value = false;
}

//toggles the delete confirmation dialog box
function openDeleteModal(){
    isDeleteModalOpen.value = true;
}

function isDataLoading(val)
{
  dataLoading.value = val;
}

function reloadWidgetData(){
  
  isDataLoading(true);
  reloadDataTrigger.value = true;
  widgetContentClass.value = 'overflow-hidden';

}

function reloadWidgetDataComplete(){
  isDataLoading(false);
  reloadDataTrigger.value = false;  
  widgetContentClass.value = 'overflow-none';
}



function redirectToWidget() {

  //set active data widget
  store.dispatch("mutation", ["currentDashboard", thisWidget.value]);

  //redirect to dash
  router.push(widgetPath.value);

}

function editWidget(){
  
  editWidgetTrigger.value = true;  

  emit('editWidget',thisWidget.value);
  


}

function duplicateWidget(){
  
  duplicateWidgetTrigger.value = true;  

  //add copy of widget
  emit('duplicateWidget',thisWidget.value);

}
 //returns the start date/time value of the dashboard time interval


 function getTimeIntervalStart(){

 //type should be "graph"

 if(props.Settings.TimeInterval) //predefined time intervals shown to end user in user interface
 {
    return addHours(new Date(),parseInt(props.Settings.TimeInterval)).toISOString();
 }
 else  
     return props.Settings.CustomTimeIntervalStart;  //custom time intervals specified by end user

}


//returns the ending date/time value of the dashboard time interval
function getTimeIntervalEnd(){

 if(props.Settings.TimeInterval)
 {
    return new Date().toISOString();
 }
 else 
     return props.Settings.CustomTimeIntervalEnd;        
 
}

 //reutrns array of parameters to be used in api call
 function getParams(paramObj){
 
 let params = [];
 for (var p of paramObj) params.push(p.param);
 
 return params;  
 }

 
async function exportData(){
      
  let returnVal = [];
  let thisParams = getParams(props.Settings.Parameters);
           
  let getTimeIntervalStartVal = getTimeIntervalStart();
  let getTimeIntervalEndVal = getTimeIntervalEnd();

  let thisStartDateTime = getTimeIntervalStartVal;
  let thisStartDateTimeUTC = convertZonedTimetoUTC(thisStartDateTime, meter.value.timeZone.offset);
  ///
  let thisEndDateTime = getTimeIntervalEndVal
  let thisEndDateTimeUTC = convertZonedTimetoUTC(thisEndDateTime,meter.value.timeZone.offset);

  let thisStartDay = format(thisStartDateTimeUTC, "yyyy") + "-" + format(thisStartDateTimeUTC, "MM") + "-" + format(thisStartDateTimeUTC, "dd");
  let thisEndDay = format(thisEndDateTimeUTC, "yyyy") + "-" + format(thisEndDateTimeUTC, "MM") + "-" + format(thisEndDateTimeUTC, "dd");
  let thisStartTime = format(thisStartDateTimeUTC, "HH") + ":" + format(thisStartDateTimeUTC, "mm");
  let thisEndTime = format(thisEndDateTimeUTC, "HH") + ":" + format(thisEndDateTimeUTC, "mm");

     var params = {
        request: "s3_DATA",
        username: userName.value,
        metername: meter.value.name,
        startDay: thisStartDay, // does a range of data
        startTime: thisStartTime, // post-request parsing
        endDay: thisEndDay,
        endTime: thisEndTime,
        isParseFifteen: true,
        topics: thisParams,
        csv: "exportfile"
      };

    await store.dispatch("s3_API", params);    
     
 
}

function exportDataComplete(){
  exportWidgetDataTrigger.value = false;  
}

 </script>
 
 <style>

 </style>