import { createRouter, createWebHistory } from 'vue-router';
import { noNavPages } from '@/framework/routes/noNav.js';
import { devPages } from '@/framework/routes/devPages.js';
import { withNavPages, bottomNavigationPages } from '@/framework/routes/withNav.js';
import store from '@/framework/store';
import { getJwtToken } from '@/scripts/cognito';

const isDevEnv = false;  // TODO: v1.3 convert to .env
// const isDevEnv = process.env.isDevEnv;

var routes = [
  ...noNavPages,
  ...bottomNavigationPages,
  {
    path: '/',
    redirect: '/welcome',
    name: "Root",
    component: () => import("@/components/page/pagetemplate.vue"),
    children: withNavPages,
  },
  {
    // Catch all Redirect
    path: '/:pathMatch(.*)*',
    redirect: "/not-found",
  }
];

// routes = process.env.isDevEnv ? routes.concat(devPages) : routes;
routes = isDevEnv ? routes.concat(devPages) : routes;
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Handle New Tab
  const isLoggedIn = store.getters.loggedinEmail;

  if (typeof isLoggedIn === 'undefined' || isLoggedIn === null || isLoggedIn === "") {
    await store.dispatch("requestLocalStorage");
    if (!store.getters.loggedinEmail) {
      store.dispatch("clearLocalStorage");
    }
  }

  const thisToken = await getJwtToken();

  if (to.matched.some(rts => rts.meta.requiresAuth)) {
    if (thisToken === null || thisToken.length == 0) {
      expiredSession();
    } else {
      next();
    }
  } else if (thisToken && to.path.toLowerCase() === '/welcome') {
    next({
      path: '/mymeters',
    });
  } else {
    next();
  }
});

function expiredSession() {
  store.dispatch("mutation", ["loginExpired", true]);
  router.push('/login')
}

export default router;
