import * as meter_data from "@/devtools/demo/salesDemo/fake_data.js";

export function getDemoPS03(store) {
    const table = getDataRange();
    const headers = Object.keys(table[0]);
    const demoPS3Meter = { headers: headers, table: table };

    // const demoPS3Meter = store.getters.demoPS3Meter;  // get a value
    // store.dispatch("mutation", ["demoPS3Meter", demoPS3Meter]);
    return demoPS3Meter;
}

export function getDataRange(start = "None", end = "None") {
    return getDataRange_(start = start, end = end);
}
function getDataRange_(start = "2023-01-01", end = "None") {
    if (start == "None") {
        start = new Date();
        start.setMonth(start.getMonth() - 3); // Subtract 3 months
    }
    if (end == "None") {
        end = new Date();
        end.setDate(end.getDate() + 1);
    }
    let data = [];
    const dayList = getDaysBetween(start, end);
    for (const day of dayList) {
        data = [...data, ...generateDay(day)];
    }

    data = correctAccumulators(data);
    // console.log("data", data);
    return data;
}

function convertCSVToArrayOfObjects(csvString) {
    const rows = csvString.trim().split('\n');
    const headers = rows[0].split(',');
    const dataObjects = rows.slice(1).map(row => {
        const values = row.split(',');
        const obj = {};
        headers.forEach((header, index) => {
            obj[header] = values[index];
        });
        return obj;
    });
    return dataObjects;
}

function generateDay(day = "2024-01-01") {
    let time = [0, 0];
    const obj = convertCSVToArrayOfObjects(meter_data.meterData);
    for (const row in obj) {
        let randPerc = (Math.random() * 2 - 1) * 0.02;
        for (const item in obj[row]) {
            if (item.includes('Net')) continue;
            obj[row][item] = (obj[row][item] * (1 + randPerc)).toFixed(3);
        }

        const hour = time[0].toString().padStart(2, '0');
        const minute = time[1].toString().padStart(2, '0');
        obj[row].date = day;
        obj[row].time = hour + ':' + minute;

        if (time[1] == 45) {
            time[0] += 1;
            time[1] = 0;
        }
        else time[1] += 15;
    }
    return obj;
}

function getDaysBetween(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const days = [];

    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        days.push(dt.toISOString().split('T')[0]); // Format as "YYYY-MM-DD"
    }

    return days;
}

function genRandStatVal(min, max, average, adj) {
    let randomValue = Math.random() * (max - min) + min;

    if (Math.random() < adj)
        randomValue = average + (randomValue - average) * adj;

    return Math.max(min, Math.min(max, randomValue));
}

function correctAccumulators(data) {
    const accums = {
        "kVAHNet/Elm/A": {
            min: 0.018,
            max: 1.496,
            average: 0.598726316,
            adj: 0.5,
        },
        "kVARHNet/Elm/A": {
            min: 0.037,
            max: 0.23,
            average: 0.095589474,
            adj: 0.5
        },
        "kWHNet/Elm/A": {
            min: 0.023,
            max: 1.43,
            average: 0.570347368,
            adj: 0.5
        }
    }

    for (const accumulator in accums) {
        const acc = accums[accumulator];
        let value = parseFloat(data[0][accumulator]);

        for (const row in data) {
            const incr = genRandStatVal(acc.min, acc.max, acc.average, acc.adj);
            data[row][accumulator] = value.toFixed(3);
            value += incr;
        }
    }
    return data;
}

export async function testFunction(store) {

    // Get the current date and time
    const now = new Date();
    const utcHours = String(now.getUTCHours()).padStart(2, "0");
    const utcMinutes = String(now.getUTCMinutes()).padStart(2, "0");
    const utcTime = `${utcHours}:${utcMinutes}`;

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const today = `${year}-${month}-${day}`;

    var params = {
        request: "s3_DATA",
        username: "dentcloud.user@gmail.com",
        // metername: "P482311252",
        metername: "P030000000",
        startDay: "2024-06-23",
        startTime: "00:00",
        endDay: today,
        endTime: "23:59",
        isParseFifteen: true,
    };
    const response = await store.dispatch("s3_API", params);
    const latest = response.latest.time;

    // console.log(params);

    // return isLater(utcTime, latest);
    return response;

}

export function getDefaultWidgets() { return _getDefaultWidgets(); }
function _getDefaultWidgets() {
    let today = new Date();
    let firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    let sevenDaysLater = new Date(firstDayLastMonth);
    sevenDaysLater.setDate(firstDayLastMonth.getDate() + 7);

    function formatDate(date) {
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const start = formatDate(firstDayLastMonth);
    const end = formatDate(sevenDaysLater);
    const now = formatDate(today);

    return {
        "statusCode": 200,
        "request": "getAllWidgets",
        "success": true,
        "widgets": {
            "wid_22WezhqysCw1rRWV5628": {
                "Comment": "Demo Billing for previous month",
                "Type": "value",
                "Parameters": [
                    {
                        "param": "kWHNet/Elm/A",
                        "uom": "kWh",
                        "displayname": "kWh/Element A"
                    }
                ],
                "TimeInterval": "lastmonth",
                "FullName": "Last Month kWh",
                "w": 4,
                "CreatedDate": now + "T18:22:55.643Z",
                "h": 2,
                "x": 6,
                "y": 0,
                "CustomTimeIntervalEnd": null,
                "CustomTimeIntervalStart": null
            },
            "wid_KiNEZmfdIvXJjI598440": {
                "Comment": "display load profile",
                "Type": "graph",
                "Parameters": [
                    {
                        "param": "A/Ch/A1",
                        "uom": "Amps",
                        "displayname": "Current/Element A/Ch 1"
                    },
                    {
                        "param": "A/Ch/A2",
                        "uom": "Amps",
                        "displayname": "Current/Element A/Ch 2"
                    },
                    {
                        "param": "A/Ch/A3",
                        "uom": "Amps",
                        "displayname": "Current/Element A/Ch 3"
                    }
                ],
                "TimeInterval": "-24",
                "FullName": "Last 24 Hours Amps",
                "w": 6,
                "CreatedDate": now + "T20:41:28.530Z",
                "h": 4,
                "CustomTimeIntervalEnd": null,
                "CustomTimeIntervalStart": null
            },
            "wid_a5VPPlBOTKbSEzlY3284": {
                "Comment": "A demo of a requested week",
                "Type": "graph",
                "Parameters": [
                    {
                        "param": "DemandkW/Elm/A",
                        "uom": "kW",
                        "displayname": "kW Demand Wattage/Element A"
                    }
                ],
                "TimeInterval": "",
                "FullName": "Single week of Demand kW",
                "w": 10,
                "CreatedDate": "2024-10-01T18:24:38.007Z",
                "h": 3,
                "CustomTimeIntervalEnd": end + "T18:19:00.000Z",
                "CustomTimeIntervalStart": start + "T18:18:00.000Z"
            },
            "wid_qpMGYD9TG0QPGUmL3249": {
                "Comment": "Current month of kWh",
                "Type": "value",
                "Parameters": [
                    {
                        "param": "kWHNet/Elm/A",
                        "uom": "kWh",
                        "displayname": "kWh/Element A"
                    }
                ],
                "TimeInterval": "currentmonth",
                "FullName": "Current Month kWh",
                "w": 4,
                "CreatedDate": now + "T18:23:23.253Z",
                "h": 2,
                "x": 6,
                "y": 2,
                "CustomTimeIntervalEnd": null,
                "CustomTimeIntervalStart": null
            }
        },
        "metername": "P030000000"
    }
}