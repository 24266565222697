const AMPLIFY_AWS_CONFIG_REGION = process.env.VUE_APP_AWS_CONFIG_REGION;
const AMPLIFY_IDENTITY_POOL_ID = process.env.VUE_APP_IVUE_APPITY_POOL_ID;
const AMPLIFY_USER_POOL_ID = process.env.VUE_APP_USER_POOL_ID;
const AMPLIFY_USER_POOL_WEB_CLIENT_ID = process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID;
const AMPLIFY_MQTT_ID = process.env.VUE_APP_MQTT_ID;

// https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/#configure-your-application
import { Amplify, Auth, Storage, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";

import store from '@/framework/store';
import router from '@/framework/router';
import * as datajs from "@/views/meters/data.js";

// Demo Functions
import * as salesDemo from '@/devtools/demo/salesDemo/salesDemo.js';

var subscription = []; // Websocket subscriptions

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AMPLIFY_AWS_CONFIG_REGION,
    identityPoolId: AMPLIFY_IDENTITY_POOL_ID,
    userPoolId: AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: { AWSS3: { isObjectLockEnabled: true } },
  Analytics: { disabled: true },
});
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: AMPLIFY_AWS_CONFIG_REGION,
    aws_pubsub_endpoint: `wss://${AMPLIFY_MQTT_ID}-ats.iot.${AMPLIFY_AWS_CONFIG_REGION}.amazonaws.com/mqtt`,
  })
);
Storage.configure({ region: AMPLIFY_AWS_CONFIG_REGION });
const AWSS3TestBucket = { bucket: 'dentcloud.testbucket.or', level: 'private' };
const AWSS3MeterDataBucket = { bucket: 'dentcloud.meterdata.or', pageSize: 1000 };


// Websocket subscription event initialize
export async function startPubSub(eventName, topic) {
  await Auth.currentCredentials().then((creds) => {

    const newSubscription = PubSub.subscribe(topic).subscribe({
      next: (data) => {
        var myEvent = new CustomEvent(eventName, {
          detail: data.value,
          topic: topic,
          credentials: creds,
          data: data, // out of scope on call?
        });
        document.dispatchEvent(myEvent);
      },
      error: (error) => console.error(error),
      close: () => console.log("Closed from some source..."),
    });
    subscription.push(newSubscription);
  });
  return;
}


// Unsubscribte to all Websocket events
export function unSubscribe() {
  if (subscription != []) {
    for (var i = 0; i < subscription.length; i++)
      subscription[i].unsubscribe();
    subscription = [];
  }
}


// Publish a message to the topic on a Websocket
export async function publishMsg(topic, message) {
  return await PubSub.publish(topic, message);
}


// Request a new Account to be email authorized
export async function signUp(email, username, password) {
  try {
    const { user } = await Auth.signUp({
      username: username,
      password: password,
      attributes: {
        email: email,
      },
    });
    return { success: true, username: username };
  } catch (error) {
    return { success: false, error: error };
  }
}


// Debug logIn only
export async function logIn() { return await SignIn("newuser69", "newpassword69"); }

// Sign into the authenticated account and acquire token
export async function signIn(username, password) { return await SignIn(username, password); }
async function SignIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    const jwtToken = await getJWTToken();
    return { success: true, user: user, token: jwtToken };
  } catch (error) {
    return { error: error, success: false, token: null };
  }
}


// Sign Out of account
export async function signOut() {
  try {
    await Auth.signOut();
    return true;
  } catch (error) { return false; }
}


// Use email code to confirm the account
export async function confirmSignUp(username, code) {
  try {
    const confirm = await Auth.confirmSignUp(username, code);
    if (confirm == "SUCCESS") return true;
    else return false;
  } catch (error) { return false; }
}


export async function resendConfirmationCode(username) {
  try {
    await Auth.resendSignUp(username);
    return { success: true };
  } catch (err) {
    return { success: false, error: err };
  }
}

// Get web token for the current account
async function getJWTToken() { return await getJwtToken(); }
export async function getJwtToken() {
  try {
    const session = await Auth.currentSession();
    const JWT = session.getIdToken().getJwtToken();
    return JWT;
  } catch (error) {

    return "";
  }
}


// Request a change to the User's Password (Logged In)
// export async function changePassword(oldPassword, newPassword) {
//   Auth.currentAuthenticatedUser().then(user => {
//     return Auth.changePassword(user, oldPassword, newPassword);
//   })
//     .then(data => console.log('changePassword data ???', data))
//     .catch(err => console.log('changePassword error', err));
// }


// Request a Password change code to email
export async function forgotPassword(username) {
  const result = await Auth.forgotPassword(username)
    .then(data => {
      console.log('forgotPassword data', data);
      return { success: true, data: data };
    })
    .catch(err => {
      console.log('forgotPassword error', err)
      return { success: false, error: err };
    });
  return result;
}


// Use code to confirm new Password (old password not required)
export async function forgetPasswordSubmit(username, code, newPassword) {
  const result = await Auth.forgotPasswordSubmit(username, code, newPassword)
    .then(data => {
      console.log('forgotPasswordSubmit data', data);
      return { success: true, data: data };
    })
    .catch(err => {
      console.log('forgotPasswordSubmit error', err)
      return { success: false, error: err };
    });
  return result;
}


// check if user token is expired or rejected
export async function parseResponse(res) {
  if ("success" in res) return res;
  else // auth expired
    if (res.statusCode == 401 || res.statusCode == 403) {
      const signout = await store.dispatch("cognito_signOut");
      store.dispatch("mutation", ["loginExpired", true]);
      router.push('/login'); // redirect to login
    }
}

export async function s3_PUT(filename = 'default.txt', content = 'noContent') {
  const username = store.getters.loggedinEmail;

  return await Storage.put(username + '/' + filename, content, AWSS3TestBucket)
    .then(result => { console.log(result); return true }) // {key: "test.txt"}
    .catch(err => { console.log(err); return false });
}

export async function s3_GET(filename = 'default.txt') {
  const username = store.getters.loggedinEmail;

  const result = await Storage.get(username + '/' + filename, AWSS3TestBucket)
    .then(result => { return result }) // {key: "test.txt"}
    .catch(err => { return false });
  if (result)  // url to s3 endpoint
    return await fetch(result).then(response => { return response.text() });
  else return false;
}

export async function s3_DATA(params) { return await s3_DATA_(params) };
async function s3_DATA_(params) {
  const isParseFifteen = "isParseFifteen" in params ? params.isParseFifteen : false;  // TODO: make this a param
  const topicList = "topics" in params ? params.topics : datajs.getParameters();

  var dataset = { headers: [], table: [] };  // return in table parse form

  const specialRequest = "metername" in params && params.metername == "P030000000";
  if (!specialRequest) {

    const fileRequests = await get_fileRequests(params);

    var resultList = [];
    for (const request of fileRequests) {
      const result = await Storage.get(request, AWSS3MeterDataBucket)
        .then(result => { return result }) // {key: "test.txt"}
        .catch(err => { return false });
      if (result) resultList.push(result);
    }

    for (const result of resultList) {
      try {
        const response = await fetch(result);

        if (response.status === 200) {
          const responseText = await response.text();
          try {
            const parseSet = parse_csvMeterData(responseText, topicList, isParseFifteen);
            dataset.headers = Array.from(new Set([...dataset.headers, ...parseSet.headers]));
            dataset.table = dataset.table.concat(parseSet.table);
          } catch (error) {
            console.log("Algorithm failed at parse_csvMeterData");
          }
        }
      } catch (error) { };
    }
    // Check for Current Day
    const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
    const todayDate = today.toISOString().slice(0, 10); // YYYY-MM-DD
    const dayRange = get_daysInRange([params.startDay, params.endDay]);

    var containsDate = fileRequests.some(file => file.includes(todayDate));
    const months = [
      "january", "february", "march", "april",
      "may", "june", "july", "august",
      "september", "october", "november", "december"
    ];
    const myMonth = months[parseInt(todayDate.split('-')[1], 10) - 1];
    containsDate = containsDate || fileRequests.some(file => file.includes(myMonth));

    if (!(containsDate) && (dayRange.includes(todayDate) || isTodayInRequest(fileRequests))) {
      var params_api = {
        request: "getMeterTable",
        username: params.username,
        metername: params.metername,
        day: todayDate,
        topics: topicList,
      };
      const response = await store.dispatch("dentcloud_API", params_api);

      if (response) {
        const parseSet = parse_dynamoMeterData(response, topicList, isParseFifteen);
        dataset.headers = Array.from(new Set([...dataset.headers, ...parseSet.headers]));
        dataset.table = dataset.table.concat(parseSet.table);
      }
    }
  } // end of normal request
  else {
    const demoData = salesDemo.getDemoPS03(store);
    dataset.headers = demoData.headers;
    dataset.table = demoData.table;
  }

  // Validate Dataset Range Request
  if (!('startDay' in params || ('range' in params && 'startDate' in params.range))) {
    return dataset;
  }
  if (!('endDay' in params || ('range' in params && 'endDate' in params.range))) {
    return dataset;
  }
  if (!('startTime' in params || ('range' in params && 'startTime' in params.range))) {
    return dataset;
  }
  if (!('endTime' in params || ('range' in params && 'endTime' in params.range))) {
    return dataset;
  }

  // Parse Data Range
  const startDay = 'startDay' in params ? params.startDay : params.range.startDate;
  const startTime = 'startTime' in params ? params.startTime : params.range.startTime;
  const endDay = 'endDay' in params ? params.endDay : params.range.endDate;
  const endTime = 'endTime' in params ? params.endTime : params.range.endTime;

  const startDate = `${startDay}T${startTime}:00Z`;
  const endDate = `${endDay}T${endTime}:00Z`;

  function isBetweenDateTime(dateTime, startDateTime, endDateTime) {
    return dateTime >= startDateTime && dateTime <= endDateTime;
  }

  dataset.table = dataset.table.filter(item => {
    const itemDateTime = new Date(`${item.date}T${item.time}Z`); // Ensure UTC
    const startDateTime = new Date(startDate); // Ensure start time is at the beginning of the day in UTC
    const endDateTime = new Date(endDate); // Ensure end time is at the end of the day in UTC

    const isBetween = isBetweenDateTime(itemDateTime, startDateTime, endDateTime);

    return isBetween;
  });

  // filter dataset headers
  dataset.headers = ['date', 'time'].concat(dataset.headers.filter(header => topicList.includes(header)));
  dataset['latest'] = dataset.table[dataset.table.length - 1];

  if ('csv' in params && params.csv) {
    let csvString = convertToCSV(dataset.headers, dataset.table);
    downloadCSV(csvString, params.csv);
    return { table: [], headers: [], latest: dataset.latest };
  }

  return dataset;
}

export async function s3_getAccumulatedValue(params) {
  params.startDay = "startDate" in params.range ? params.range.startDate : false;
  params.endDay = "endDate" in params.range ? params.range.endDate : false;

  const specialRequest = 'metername' in params ? params.metername == 'P030000000' : false;

  const fileRequests = await get_fileRequests(params, true);
  if (fileRequests.length > 1) {
    const startDay = (fileRequests[0].split('/')[4]).split('.')[0];
    const endDay = (fileRequests[fileRequests.length - 1].split('/')[4]).split('.')[0];

    delete (params.endDay);
    delete (params.startDay);
    params.range.startDate = startDay;
    params.range.endDate = endDay;

    const response = await store.dispatch("dentcloud_API", params);
    return response;
  }
  else if (specialRequest) {
    const response = await store.dispatch("dentcloud_API", params);
    return response;
  }
  else return { success: false, error: "no valid days in range" };

}

function downloadCSV(csvString, filename) {
  const blob = new Blob([csvString], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


async function get_fileRequests(params, onlyDays = false) {
  const metername = params.metername;
  const startDate = new Date(params.startDay);
  const endDate = new Date(params.endDay);

  const dataByMonth = {};  // parse the range to optimize requests
  while (startDate <= endDate) {
    const year = startDate.getUTCFullYear();
    const month = startDate.getUTCMonth(); // Zero-based index of the month
    const monthName = (new Date(Date.UTC(0, month, 1))).toLocaleString('en-US', { month: 'long', timeZone: 'UTC' });
    const dateKey = startDate.toISOString().slice(0, 10);

    if (!dataByMonth[year]) { dataByMonth[year] = {}; }
    if (!dataByMonth[year][monthName]) { dataByMonth[year][monthName] = []; }
    dataByMonth[year][monthName].push(dateKey);

    startDate.setUTCDate(startDate.getUTCDate() + 1);
  }

  var requests = [];
  for (const year in dataByMonth) {
    var storageList = (await Storage.list(metername + '/' + year, AWSS3MeterDataBucket)).results;
    storageList = storageList.map(item => item.key);
    if (storageList.length == 0) continue;  // There is no Data for that year

    for (const month in dataByMonth[year]) {
      const monthLength = Object.keys(dataByMonth[year][month]).length;
      if (monthLength > 9 && !onlyDays) {
        const fileLoc = `${metername}/${year}/${month.toLowerCase()}/${year}-${month.toLowerCase()}.csv`;
        if (storageList.includes(fileLoc)) requests.push(fileLoc);
        continue; // don't need to look at days if requesting enough data
      }
      for (const day in dataByMonth[year][month]) {
        const fileLoc = `${metername}/${year}/${month.toLowerCase()}/day/${dataByMonth[year][month][day]}.csv`
        if (storageList.includes(fileLoc)) requests.push(fileLoc);
      }
    }
  }
  return requests;
}

function isTodayInRequest(dataArray) {
  const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
  const todayYearMonth = today.toISOString().slice(0, 7); // YYYY-MM
  const todayDate = today.toISOString().slice(0, 10); // YYYY-MM-DD

  function getMonthNumber(monthName) {
    const months = [
      "january", "february", "march", "april",
      "may", "june", "july", "august",
      "september", "october", "november", "december"
    ];
    const index = months.indexOf(monthName.toLowerCase()) + 1;
    return index < 10 ? "0" + index : index.toString();
  }
  for (const item of dataArray) {
    const parts = item.split('/');
    const yearMonth = parts[1] + '-' + getMonthNumber(parts[2]);
    const day = parts.includes('day') ? parts[4]?.split('.')[0] : undefined;
    if ((!parts.includes('day')) && yearMonth === todayYearMonth) return true;
    if (day === todayDate) return true;
  }
  return false;
}


function get_daysInRange(dayRange) {
  const [start, end] = (dayRange[0] <= dayRange[1]) ? dayRange : dayRange.reverse();

  const dateArray = [];
  const currentDate = new Date(start);
  while (currentDate.toISOString().split('T')[0] <= end) {
    dateArray.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

function parse_csvMeterData(data, topics, isParseFifteen) {
  const dataset = { headers: [], table: [] };
  const lines = data.trim().split('\n');
  const headerRow = lines[0].split(',');

  dataset.headers = headerRow; // Extract headers and projection expression
  topics = ['date', 'time', ...topics];

  for (let i = 1; i < lines.length; i++) {
    const row = lines[i].split(',');
    const date = row[0];
    const time = row[1];

    // Initialize a new rowObject
    let rowObject = {};

    rowObject.date = date;
    rowObject.time = time;

    for (let j = 2; j < row.length; j++) {
      if (topics.includes(headerRow[j]) && row[j] !== "") {
        rowObject[headerRow[j]] = row[j];
      }
    }

    dataset.table.push(rowObject);
  }

  if (isParseFifteen) dataset.table = getClosestFifteen(dataset.table);
  return dataset;
}

function parse_dynamoMeterData(dataset, topics, isParseFifteen) {
  function isValidDateFormat(dateString) { return /^\d{4}-\d{2}-\d{2}$/.test(dateString) }
  function isValidTimeFormat(timeString) { return /^([01]\d|2[0-3]):([0-5]\d)$/.test(timeString) }
  let dataRows = dataset.table.rows.filter(obj => isValidDateFormat(obj.date) && isValidTimeFormat(obj.time));
  let dataHeaders = ['date', 'time'].concat(dataset.table.headers.filter(header => header.length >= 3));
  let csvDataset = convertToCSV(dataHeaders, dataRows);
  return parse_csvMeterData(csvDataset, topics, isParseFifteen);
}


function convertToCSV(headers, table) {
  let csvString = headers.join(',') + '\n';
  table.forEach(row => {
    const values = headers.map(key => row[key]);
    csvString += values.join(',') + '\n';
  });
  return csvString;
}


const testData_getClosestFifteen = [
  { date: "2024-08-08", time: "00:00", value: "-1" },
  { date: "2024-08-08", time: "15:10", value: "1" },
  { date: "2024-08-08", time: "15:14", value: "2" },
  { date: "2024-08-08", time: "15:15", value: "3" },
  { date: "2024-08-08", time: "15:29", value: "4" },
  { date: "2024-08-08", time: "15:31", value: "5" },
  { date: "2024-08-08", time: "15:40", value: "6" },
  { date: "2024-08-08", time: "15:50", value: "7" },
  { date: "2024-08-08", time: "16:50", value: "8" },
  { date: "2024-08-08", time: "16:59", value: "9" },
  { date: "2024-08-08", time: "23:59", value: "0" }
];

function getClosestFifteen(data) {
  // data = testData_getClosestFifteen;
  function roundToNearestQuarterHour(dateString, timeString) {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes] = timeString.split(':').map(Number);

    // Create a Date object in UTC
    let date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Round to the nearest 15 minutes
    let roundedMinutes = Math.round(date.getUTCMinutes() / 15) * 15;

    // Handle minute rollover and adjust hour and day if needed
    if (roundedMinutes === 60) {
      roundedMinutes = 0;
      date.setUTCHours(date.getUTCHours() + 1);
    }

    if (date.getUTCHours() === 24) {
      date.setUTCHours(0);
      date.setUTCDate(date.getUTCDate() + 1);
    }
    date.setUTCMinutes(roundedMinutes);

    // Format the date and time in UTC
    const roundedDate = date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
    const roundedTime = date.toISOString().split('T')[1].slice(0, 5); // Extract HH:MM

    return [roundedDate, roundedTime];
  }

  var closest = {};
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if ('date' in item && item.date == '') continue;
    else if ('time' in item && item.time == '') continue;
    else if (!('date' in item && 'time' in item)) continue;

    const [roundedDate, roundedTime] = roundToNearestQuarterHour(item.date, item.time);
    const key = `${roundedDate} ${roundedTime}`;

    if (!closest[key]) closest[key] = item;
    else {
      const currentMinutes = parseInt(item.time.split(':')[1], 10);
      const closestMinutes = parseInt(closest[key].time.split(':')[1], 10);
      const roundedMinutes = parseInt(roundedTime.split(':')[1], 10);

      if (Math.abs(currentMinutes - roundedMinutes) < Math.abs(closestMinutes - roundedMinutes))
        closest[key] = item;
    }
  }

  const result = Object.entries(closest).map(([key, item]) => {
    const keySplit = key.split(' ');
    return { ...item, date: keySplit[0], time: keySplit[1] }
  });

  return result;
}








